//Needed to install pwa
import NotificationManager from "../pwa/notification_manager";
import device from "../pwa/device_manager";

document.addEventListener('DOMContentLoaded', function() {
  NotificationManager.init();
  if (!device.isIos()) {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      NotificationManager.showRegisterElement(e);
    });
  }
});
