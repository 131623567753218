import device from "./device_manager";
import CookieStorage from '../polyfills/cookie'
import Switch from "../form/switch";

const NotificationManager = (function() {

  let deferredPrompt;
  let registerElements;

  function init() {
    registerElements = document.querySelectorAll('[data-register-device]');
    if (!registerElements || registerElements.length == 0) { return; }

    document.addEventListener("online_changed", function(event) {
      if (event.detail.network_status === "offline") {
        hideRegisterElements();
      }
    });

    if (isRegistered() === false) {
      if (device.isIos() && !device.isStandAlone()) {
        showRegisterElement(null)
      }

      if (!device.isChrome() && !device.isIos()) {
        disableRegisterElement()
      }
    }
  }

  function showRegisterElement(e) {
    deferredPrompt = e;
    registerElements.forEach(registerElement => {
      registerElement.classList.remove('hidden');

      if (deferredPrompt) {
        registerElement.querySelector('input').removeEventListener('change', showNativeNotification);
        registerElement.querySelector('input').addEventListener('change', showNativeNotification);
      } else {
        registerElement.querySelector('input').removeEventListener('change', showIosAddToHomeScreenNotification);
        registerElement.querySelector('input').addEventListener('change', showIosAddToHomeScreenNotification);
      }
    });
  }

  function disableRegisterElement() {
    console.log('disabling register elements')
    registerElements.forEach(registerElement => {
      registerElement.classList.remove('hidden');
      registerElement.classList.add('disabled');
    });
  }

  function showIosAddToHomeScreenNotification(e){
    if (e.currentTarget.checked == false) { return;}

    const popUpElement = document.getElementById('addToHomeIosPopup')
    Expander.show(popUpElement)

    Switch.setSwitch(e.currentTarget, false);
  }

  function showNativeNotification(e) {
    if (e.currentTarget.checked == false) { return;}

    handleDeferredPrompt(e.currentTarget);
  }

  function handleDeferredPrompt(element) {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice
        .then(function(choiceResult) {
          if (choiceResult.outcome === 'accepted') {
            if (device.isIos()) {
              CookieStorage.setCookie('device_type', 'ios_pwa')
            } else {
              CookieStorage.setCookie('device_type', 'android_pwa')
            }
            Switch.setSwitch(element, true);
            location.reload();
          } else {
            //TODO ergens iets opslaan dat de gebruiker hem bewust heeft geannuleerd?
            Switch.setSwitch(element, false);
          }
          deferredPrompt = null;
        });
    }
  }

  function hideRegisterElements() {
    registerElements.forEach(registerElement => {
      registerElement.classList.add('hidden');
    });
  }

  function isRegistered() {
    return CookieStorage.getCookie('device_type') != null
  }

  return {
    showRegisterElement: showRegisterElement,
    init: init
  }
})();

export default NotificationManager;
